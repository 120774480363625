import { Aurelia } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import * as Bluebird from "bluebird";
import { LogManager } from "aurelia-framework";
import { ConsoleAppender } from "aurelia-logging-console";
import { SentryAppender, Levels } from "aurelia-sentry";
import { I18N, Backend, TCustomAttribute } from "aurelia-i18n";

LogManager.addAppender(new ConsoleAppender());
LogManager.addAppender(new SentryAppender({ minLevel: Levels.warning }));
LogManager.setLevel(LogManager.logLevel.warn);

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: { wForgottenReturn: false } });

export async function configure(aurelia: Aurelia) {
  aurelia.use.standardConfiguration().feature(PLATFORM.moduleName("resources/index"));

  // Added plugins
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-google-analytics"), (config: any) => {
    config.init("UA-2779760-6");
    config.attach({
      logging: {
        // Set to `true` to have some log messages appear in the browser console.
        enabled: true,
      },
      pageTracking: {
        // Set to `false` to disable in non-production environments.
        enabled: true,
        // Configure fragments/routes/route names to ignore page tracking for
        ignore: {
          fragments: [], // Ignore a route fragment, login fragment for example: ['/login']
          routes: [], // Ignore a route, login route for example: ['login']
          routeNames: [], // Ignore a route name, login route name for example: ['login-route']
        },
        // Optional. By default it gets the title from payload.instruction.config.title.
        getTitle: (payload: any) => {
          // For example, if you want to retrieve the tile from the document instead override with the following.
          return document.title;
        },
        // Optional. By default it gets the URL fragment from payload.instruction.fragment.
        getUrl: (payload: any) => {
          // For example, if you want to get full URL each time override with the following.
          return window.location.href;
        },
      },
      clickTracking: {
        // Set to `false` to disable in non-production environments.
        enabled: true,
        // Optional. By default it tracks clicks on anchors and buttons.
        filter: (element: any) => {
          // For example, if you want to also track clicks on span elements override with the following.
          return (
            element instanceof HTMLElement &&
            (element.nodeName.toLowerCase() === "a" ||
              element.nodeName.toLowerCase() === "button" ||
              element.nodeName.toLowerCase() === "span")
          );
        },
      },
      exceptionTracking: {
        // Set to `false` to disable in non-production environments.
        enabled: true,
      },
    });
  });

  aurelia.use.plugin(PLATFORM.moduleName("aurelia-i18n"), (instance: I18N) => {
    const aliases = ["t", "i18n"];

    TCustomAttribute.configureAliases(aliases);
    instance.i18next.use(Backend.with(aurelia.loader));

    // adapt options to your needs (see http://i18next.com/docs/options/)
    return instance.setup({
      backend: {
        // this doesn't like dashes and will lose everything after "-" from "lng"
        // even though loads default "lng" just fine and second attempt of other languages too
        loadPath: "locales/{{lng}}.json",
      },
      attributes: aliases,
      lng: "et_EE",
      fallbackLng: "et_EE",
      debug: false,
    });
  });

  aurelia.use.plugin(PLATFORM.moduleName("aurelia-cookie"));
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-configuration"), (config: any) => {
    config.setEnvironments({
      local: ["localhost:8080"],
      dev: ["develop.client.tk.salome.ee.nuhvel.eu"],
      stage: ["staging.client.tk.salome.ee.nuhvel.eu"],
      prod: ["client.tk.salome.ee.nuhvel.eu", "tk.salome.ee"],
    });
  });

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  //aurelia.use.developmentLogging('debug');

  /*if (environment.testing)
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));*/

  return aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName("main-router")));
}
